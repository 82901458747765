export default function googleAnalyticsScript(){
    if (process.env.NODE_ENV !== 'production'){
        console.log('analytics: dev');
        return;
    }
    window.gtag('config', 'UA-42459727-2',
        {
            'page_path': window.location.pathname,
            // 'user_id': user.id
        }
    );
};
