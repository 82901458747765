// import ban from "../assets/img/brand/ban.png";
import ban_square from "../assets/img/brand/ban-square.png";

const dev_config = {
  apiUrl: "http://localhost:8080",
  kamchatka: "Pg%24p{#",
};

const prod_config = {
  // apiUrl: 'http://lexafrica.amadou-partenaires.com',
  apiUrl: "http://localhost:8080",
  kamchatka: "eV6#{6U)",
};

const config = {
  dev: dev_config,
  prod: prod_config,
};

const env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "dev";

const settings = {
  title: "Nazer AMINOU",
  siteUrl: "https://nazeraminou.com",
  description: `Founder & CTO @ Beans Technologies 🛍✨🚀
  @kissohq 🧠 🎨🖥⚡️
  Ex Software Engineer @microsoft`,
  keywords: "nazer, aminou, nasser, technology, digital",
  // ban:ban,
  ban: ban_square,
  ban_square: ban_square,
  hiEmail: "nazer.aminou@outlook.com",
  isDev: process.env.REACT_APP_ENV === "dev",
  config: config[env],
  brandColor: "#136C0A",
  version: "0.1.0",
};

export default settings;
