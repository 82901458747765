/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import "./Profile.css";

export default function Profile() {
  const ch = "https://www.joinclubhouse.com/@nas2lor"
  return (
    <div className=" inline-block">
      <div>
        <div className="nazer-img rounded-full bg-grey-dark shadow-md w-32 h-32 inline-block align-middle" />
        <div className="ml-8 inline-block align-middle">
          <h1
            className="text-5xl font-black profile-name text-theme-text center"
            title="Nasser AMINOU"
          >
            Nasser A.
          </h1>
          <div className="mt-1">
            <div
              className="text-theme-text-dark bg-theme-bkd-light border border-grey cursor-noselect inline-block px-3 cursor-pointer  py-1 rounded-lg text-sm"
              onClick={() =>
                window.$crisp && window.$crisp.push(["do", "chat:open"])
                // window.open(ch,'_blank')
              }
            >
              👋 Let's talk
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
