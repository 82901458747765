import React from 'react';

import googleAnalyticsScript from '../utils/analytics';
import Seo from "../components/Seo";

import Header from '../components/Header';
import Footer from '../components/Footer';

class Base extends React.Component {
    constructor(props){
        super(props);
        googleAnalyticsScript()
    }

    render() {
        const {path, title, info, viewClass, header = true, footer = true,} = this.props;
        // const path = this.props.location.pathname;
        return (
            <div className={`base-view ${viewClass}`}>
                <Seo page={title} path={path} info={info}/>
                {header && <Header/>}
                    {this.props.children}
                {footer && <Footer/>}
            </div>
        );
    }
};

export default Base;
