import logo_linkedin from "../assets/img/social/linkedin.svg";
import logo_twitter from "../assets/img/social/twitter.svg";
import logo_github from "../assets/img/social/github.svg";
import logo_dribbble from "../assets/img/social/dribbble.svg";
import logo_instagram from "../assets/img/social/instagram.svg";
import logo_vsco from "../assets/img/social/vsco.svg";
import logo_mail from "../assets/img/social/mail.svg";

const socials = [
  {
    name: "LinkedIn",
    website: "https://linkedin.com/in/nazeraminou/",
    logo: logo_linkedin,
    theme: "#0278d1",
  },
  {
    name: "Twitter",
    website: "https://twitter.com/nas2lor",
    logo: logo_twitter,
    theme: "#0278d1",
  },
  {
    name: "Github",
    website: "https://github.com/0xNazer",
    logo: logo_github,
    theme: "#0278d1",
  },
  {
    name: "Dribbble",
    website: "https://dribbble.com/nas2lor",
    logo: logo_dribbble,
    theme: "#0278d1",
  },
  {
    name: "Instagram",
    website: "https://instagram.com/nas2lor/",
    logo: logo_instagram,
    theme: "#0278d1",
  },
  {
    name: "VSCO",
    website: "https://vsco.co/nas2lor",
    logo: logo_vsco,
    theme: "#0278d1",
  },
  {
    name: "email",
    website: "mailto:nazer.aminou@outlook.com",
    logo: logo_mail,
    theme: "#0278d1",
  },
];

export default socials;
