import logo_beans from "../assets/img/brand/beans.svg";
import logo_s42 from "../assets/img/brand/s42.svg";
import logo_kisso from "../assets/img/brand/kisso.png";
import logo_trellix from "../assets/img/brand/trellix.png";
// import logo_obba from "../assets/img/brand/obba.svg";
// import logo_picsule from "../assets/img/brand/picsule.svg";
// import logo_4gf from "../assets/img/brand/4gf.svg";

const companies = [
  {
    name: "Beans Technologies",
    website: "www.trybeans.com",
    logo: logo_beans,
    title: "Supercharged Online Marketing",
    theme: "#0278d1",
  },
  {
    name: "Trellix",
    website: "www.trellix.io",
    logo: logo_trellix,
    title: "Unlocking large impact for society with AI",
    theme: "#7C017C",
  },
  {
    name: "Kisso Industries",
    website: "www.kissohq.com",
    logo: logo_kisso,
    title: "Digital Services and Infrastructures",
    theme: "#ef4955",
  },
//   {
//     name: "Studio 42",
//     website: "www.studio42.xyz",
//     logo: logo_s42,
//     // title: "Innovation Studio",
//     title: "Strategy Design Consultancy",
//     theme: "#3376a6",
//   },

  //   {
  //     name: "Optimiam",
  //     website: "www.optimiam.com",
  //     logo: logo_optimiam,
  //     title: "L'appli gourmande et anti-gaspi",
  //     theme: "#ef4955",
  //   },
  // {
  //     name: "Obba Trade, Inc",
  //     website: "/",
  //     logo: logo_obba,
  //     title: "Africa's commodity market",
  //     theme: "#136C0B",
  // },
  // {
  //     name: "Picsule",
  //     website: null,
  //     logo: logo_picsule,
  //     title: "Mobile photography curation",
  //     theme: "#D25527",
  // },
  // {
  //     name: "4Ground Family",
  //     website: "eip.epitech.eu/2014/4groundfamily/",
  //     logo: logo_4gf,
  //     title: "Household management made easy",
  //     theme: "#ffffff",
  // },
];

export default companies;
