import React from "react";
import Helmet from "react-helmet";
import settings from "../utils/settings";

const schemaOrgJSONLD = [
  {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: settings.siteUrl,
    name: settings.title,
    alternateName: settings.title,
  },
];

const Seo = ({ page, path, info }) => {
  const name = settings.title;
  const title = page ? page + " · " + settings.title : settings.title;
  const description = info ? info : settings.description;
  const url = path ? settings.siteUrl + path : settings.siteUrl;
  const keywords = settings.keywords;

  const ban = settings.siteUrl + settings.ban;
  const ban_square = settings.siteUrl + settings.ban_square;

  return (
    <Helmet title={title}>
      {/* General tags */}
      <meta name="description" content={description} />
      <meta name="image" content={ban} />
      <meta name="keywords" content={keywords} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={name} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ban_square} />
      {/*<meta property="fb:app_id" content={config.fbAppID}/>*/}

      {/* Twitter Card tags */}
      <meta name="twitter:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={name} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ban} />
      {/*<meta name="twitter:creator" content={config.twitter}/>*/}

      {/*Shema.org microdata*/}
      <meta itemProp="name" content="" />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={ban} />
    </Helmet>
  );
};

export default Seo;
