import React from "react";

import Base from "./Base";
import Profile from "../components/Profile";
import Company from "../components/Company";

import companies from "../db/companies";
import socials from "../db/socials";

class LandingView extends React.Component {
  render() {
    return (
      <Base viewClass="bg-theme-bkd  " {...this.props}>
        <div className="max-w-2xl mx-auto pt-32 px-4 text-center">
          <div className=" inline-block text-left pb-24 w-ful">
            <Profile />
            <div className="mt-8">
              {socials.map((social, index) => (
                <a key={index}
                  className="bg-theme-bkd-light animate-hover-bounce-4 p-1 rounded-full inline-block shadow- sha mr-2 cursor-pointer"
                  href={social.website}
                  target="_blank"
                  title={social.name}
                  rel="noopener noreferrer">
                  <div className="inline-block w-8 h-8 p-1 align-middle">
                    <img
                      className=" cursor-nodrag "
                      src={social.logo}
                      alt={social.name}
                    />
                  </div>
                </a>
              ))}
            </div>
            <div className="mt-8">
                <div className="text-theme-text-dark">
                    Worked for Microsoft as a Software Engineer, now exploring.
                </div>
                <div className="text-theme-text-dark mt-3">
                    Studied at Epitech, Cal State University Long Beach, UC Berkeley and HEC Paris
                </div>
            </div>
            <div className="mt-16">
              <div className="text-2xl text-theme-text-light tracking-wide font-medium inline-block rounded ">
                Check out
              </div>
              <div className="pt-8 ">
                {companies.map((company, index) => (
                  <Company key={index} company={company} isLastItem={index === (companies.length - 1)} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Base>
    );
  }
}

export default LandingView;
