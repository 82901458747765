import React from "react";

const Company = ({ index, company, isLastItem }) => (
  <a
    className={"bg-theme-bkd-light animate-hover-bounce-4 w-80 p-4 inline-block shadow-box cursor-pointer" + (!isLastItem ? " mr-10 mb-12" : "")}
    href={company.website ? `https://${company.website}/?ref=nazeraminou.com` : ""}
    target="_blank"
    rel="noopener noreferrer">
    <div className="inline-block w-8 h-8 align-middle">
      <img className=" cursor-nodrag" src={company.logo} alt={company.name} />
    </div>
    <div className="inline-block pl-2 align-middle">
      <div className="text-base text-theme-text-dark">
        {company.name}
      </div>
      <div className="text-xs text-theme-text-light font-normal mt-1 ">
        {company.title}
      </div>
    </div>
  </a>
);

export default Company;
